import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Logo from '../components/Images/Logo';
import PractiseeGeLogo from '../components/Images/PractiseeGe';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import { Formik } from 'formik';
import firebase from 'gatsby-plugin-firebase';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import energyLogo from '../icons/Energy.svg';
import transformasiLogo from '../icons/Transformation.svg';
import kebijakanLogo from '../icons/Kebijakan.svg';
import keuanganLogo from '../icons/Keuangan.svg';
import timelineImage from '../icons/TimelineFix.svg';
import timelineImageHP from '../icons/TimelineFix-HP.svg';
import thumbnailposter from '../img/posterwebinar.png';
import thumbnailposter2 from '../img/posterwebinar2.png';
import firstplace from '../icons/firstplace-idr.svg';
import secondplace from '../icons/secondplace-idr.svg';
import thirdplace from '../icons/thirdplace-idr.svg';
import one from '../icons/1.svg';
import two from '../icons/2.svg';
import three from '../icons/3.svg';
import four from '../icons/4.svg';

const Index = () => (
  <Layout withRegister>
    <SEO title="Home" />
    <Slider dots={true} autoplaySpeed={3000} arrows={false} autoplay={true}>
      <section className="my-2">
        <div 
          className="py-10 md:py-20"
          style={{
            backgroundImage: `url("https://i.ibb.co/dbvsWLt/Group-66.png")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container mx-auto px-8 lg:flex">
            <div className="text-center lg:text-left">
              <div className="lg:w-4/6">
                <Logo />
              </div>
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                GE x SRE
                <br />
                Youth Idea Competition 2021
              </h1>
              <p className="text-xl lg:text-2xl mt-6 font-bold">
                Share your ideas and get training opportunities with GE Indonesia mentors!
              </p>
              <p className="mt-8 md:mt-12">
                <Link to="/register">
                  <Button className="rounded-full">Register Now</Button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="my-2">
        <div
          className="py-10 md:py-20"
          style={{
            backgroundImage: `url("https://i.ibb.co/HCXp847/domenico-loia-Eh-Tc-C9s-YXsw-unsplash-1.png")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container mx-auto lg:flex lg:justify-center my-16 lg:my-10">
            <div className="text-center text-white">
              <div className="flex justify-center my-4 lg:my-8">
                <div className="w-4/6">
                  <PractiseeGeLogo />
                </div>
              </div>
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                GE will be on our platform soon!
              </h1>
              <p className="text-xl lg:text-2xl mt-6 mx-8">
                Drop your email for further information about available scholarship!
              </p>
                <Formik
                  initialValues={{
                    email: ''
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      alert('Incorrect email format');
                      return;
                    }
                    firebase
                      .database()
                      .ref(`emailNotification`)
                      .push(values.email)
                      .then(() => {
                        alert('Success!');
                        setSubmitting(false);
                      })
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit} className="mt-8 md:mt-12 lg:flex gap-4 mx-4 lg:mx-auto">
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="john.doe13@example.com"
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        required={true}
                      />
                      <button type="submit" className="bg-gray-300 hover:bg-gray-500 shadow-sm focus:outline-none rounded text-black px-3 py-2 my-2 font-bold">
                        Send
                      </button>
                    </form>
                  )}
                </Formik>
            </div>
          </div>
        </div>
      </section>
    </Slider>
    <section>
      <div className="container mx-auto text-center flex items-center flex-col">
        <div className="m-8 md:m-12 text-center md:text-left">
          <h1 className="text-2xl md:text-4xl font-bold text-center">What is GE x SRE YIC?</h1>
          <p className="text-base md:text-lg mb-1 mt-4 text-center">
            <b>GE x SRE YIC 2021</b> is a medium for students to share solutions for
            <b> renewable energy</b> development ideas in 4 main categories. Each team will have the
            opportunity to present their ideas in the form of a White Paper for this competition.
            The solutions offered may include (but are not limited to): new product design, business
            ideas, implementation of new systems, etc. <b>The top 10 finalists</b> will have a
            chance to discuss their idea with GE Indonesia mentors so the top 10 finalist can
            further improve the solution strategy and to prepare for the final presentation stage.
          </p>
        </div>
      </div>
    </section>
    <section id="theme" className="md:py-10">
      <div className="container mx-auto text-center flex items-center flex-col">
        <h1 className="text-2xl md:text-4xl font-bold">Theme</h1>
        <p className=" md:text-xl mt-4 lg:px-24">
          “Pushing the Limits to Endless Discovery in Decarbonization and Electrification through
          Renewable Energy.”
        </p>
      </div>
    </section>
    <section id="sub-theme" className="px-10 py-20">
      <div className="container mx-auto text-center flex items-center flex-col">
        <h1 className="text-2xl md:text-4xl font-bold mb-5">Subtheme</h1>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-12 mt-2">
          <div className="rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={energyLogo} alt="energy" className="mx-auto" />
            <br></br>
            <p className="font-bold text-2xl">Energy Production and Storage</p>
            <p className="text-base md:text-lg mt-4">
              Technology innovation for the production and storage of renewable energy with low
              carbon emissions to meet national electrification needs.
            </p>
          </div>
          <div className="rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={transformasiLogo} alt="transformasi" className="mx-auto" />
            <br></br>
            <p className="font-bold text-2xl">Digital Transformation</p>
            <p className="text-base md:text-lg mt-4">
              Innovation through software to optimize energy processes so that energy services can
              meet challenges such as development and productivity.
            </p>
          </div>
          <div className="rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={kebijakanLogo} alt="kebijakan" className="mx-auto" />
            <br></br>
            <p className="font-bold text-2xl">Public and Social Policy</p>
            <p className="text-base md:text-lg mt-4">
              Policy review and possible innovation for the usage of renewable energy, with
              consideration for its societal conditions, impacts, and roles.
            </p>
          </div>
          <div className="rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={keuanganLogo} alt="energy" className="mx-auto" />
            <br></br>
            <p className="font-bold text-2xl"> Economics and Finance </p>
            <p className="text-base md:text-lg mt-4">
              Innovation to achieve economic benefits with the usage of renewable energy.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="challenge" className="px-10 py-20">
      <div className="container mx-auto flex items-center flex-col">
        <h1 className="text-2xl md:text-4xl font-bold mb-5">Challenges To Be Solved</h1>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-12 mt-2">
          <div className="flex rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={one} alt="one" className="w-1/2 md:w-16 mr-4" />
            <p className="text-base md:text-lg text-justify inline-block mt-3">
              The world needs a large-scale <b>energy</b> that is <b>safe, low carbon,</b> and{' '}
              <b>cheap</b>.
            </p>
          </div>
          <div className="flex rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={two} alt="two" className="w-1/2 md:w-16 mr-4" />
            <p className="text-base md:text-lg text-justify inline-block align-middle">
              The electricity sector emits <b>13.6 billion</b> tonnes of Carbon Dioxide (
              <b>
                CO<sub>2</sub>
              </b>
              ) into the atmosphere, 41 percent of total global emissions.
            </p>
          </div>
          <div className="flex rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={three} alt="three" className="w-1/2 md:w-16 mr-4" />
            <p className="text-base md:text-lg text-justify inline-block align-middle">
              Energy <b>demand</b> of more than 17.000 islands in Indonesian state can be{' '}
              <b>increased</b> up to <b>threefold</b> between 2015 and 2030.
            </p>
          </div>
          <div className="flex rounded-md md:rounded-xl shadow-md md:shadow-lg p-8">
            <img src={four} alt="four" className="w-1/2 md:w-16 mr-4" />
            <p className="text-base md:text-lg text-justify inline-block align-middle mt-3">
              <b>Dependence</b> on domestic <b>coal</b> and demand for imported oil products is{' '}
              <b>increasing</b>.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="prize" className="px-10 py-20">
      <div className="container mx-auto text-center flex items-center flex-col">
        <h1 className="text-2xl md:text-4xl font-bold">Awards and Incentives</h1>
        <p className="text-base md:text-lg mb-1 mt-2 text-center">
          Winners of the GExSRE Youth Idea Competition 2021 will get the prizes below
        </p>{' '}
        <br />
        <div className="w-full gap-12 flex justify-center grid grid-cols-1 md:grid-cols-3">
          <div className="mt-8 md:mt-4 shadow-md rounded-sm lg:rounded-xl">
            <img src={firstplace} alt="firstplace" className="mx-auto w-1/2" />
          </div>
          <div className="mt-8 md:mt-4 shadow-md rounded-sm lg:rounded-xl">
            <img src={secondplace} alt="secondplace" className="mx-auto w-1/2" />
          </div>
          <div className="mt-8 md:mt-4 shadow-md rounded-sm lg:rounded-xl">
            <img src={thirdplace} alt="thirdplace" className="mx-auto w-1/2" />
          </div>
        </div>
      </div>
    </section>

    {/* Start Section Timeline */}
    <section id="timeline" className="">
      <h1 className="text-2xl md:text-4xl font-bold mb-5 mt-4 text-center">Timeline</h1>
      <div className="hidden md:flex justify-center">
        <img src={timelineImage} alt="" />
      </div>
      <div className="flex md:hidden justify-center">
        <img src={timelineImageHP} alt="" />
      </div>
    </section>
    {/* End of Section Timeline */}

    {/* Start Section Webinar*/}
    <section id="webinar" className="px-10 py-20">
      <div className="container mx-auto flex flex-col">
        <h1 className="text-2xl md:text-4xl font-bold mb-5 text-center">Webinar</h1>
        <div className="w-full flex justify-center grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="shadow-md rounded-sm lg:rounded-xl">
            <img src={thumbnailposter} alt="energy" className="mx-auto" /> <br />
            <div className="description m-8">
              <p className="font-bold text-xl md:text-2xl">
                Webinar Path Towards Energy Transition in Indonesia
              </p>
              <p className="text-base md:text-lg mt-4">
                a webinar with the theme of the energy transition in Indonesia which will discuss
                decarbonization, the efforts that have been and will be used to support the
                transition process.
              </p>
              <div className="mt-5">
                <Link to="/firstevent">
                  <Button className="rounded-full">Read More</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="shadow-md rounded-sm lg:rounded-xl">
            <img src={thumbnailposter2} alt="energy" className="mx-auto" /> <br />
            <div className="description m-8">
              <p className="font-bold text-xl md:text-2xl">
                Webinar - Unearthing Potential: Next Big Thing of RE
              </p>
              <p className="text-base md:text-lg mt-4">
                A webinar to disseminate information on new and renewable energy, 
                with the theme Unearthing Potential: Next Big Thing of RE, with three subthemes:
              </p>
              <div className="mt-5">
                <Link to="/secondevent">
                  <Button className="rounded-full">Read More</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End Section Webinar*/}
  </Layout>
);

export default Index;
